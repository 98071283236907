// @ts-ignore
import AuthModal from "components/auth/modal";
// @ts-ignore
import Seo from "components/common/seo";
import { useRouter } from "next/router";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";

export default function LoginPage() {
  const router = useRouter();
  const { token, user } = router.query;

  const userProfile = useSelector((state: any) => state.auth.userProfile);

  useEffect(() => {
    if (token && user && userProfile) {
      router.push(
        `/${userProfile?.stores[0]?.storeId}?token=${token}&user=${user}`
      );
    }
  }, [token, user, userProfile]);

  return (
    <>
      <Seo title="Login to Continue" />
      <main className="flex h-screen w-screen items-center justify-center bg-white-50">
        <AuthModal />
      </main>
    </>
  );
}
